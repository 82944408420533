<template>
  <div class="aw-empty">
    <img
      :src="
        invalidCourse
          ? require('@/assets/images/awards/empty/warning.svg')
          : require('@/assets/images/awards/empty/medal.svg')
      "
      :alt="invalidCourse ? 'warning' : 'medal'"
      width="64"
      height="64"
    />
    <div class="aw-empty__content">
      <h5>
        {{
          invalidCourse
            ? "Ups, something went wrong!"
            : "Let's celebrate your learners' successes!"
        }}
      </h5>
      <p v-if="invalidCourse">
        It looks like we where not able to load this page properly for
        you.Please try again
      </p>
      <p v-else-if="awardType === 'who'">
        It looks like there are no awards set up for this course yet. Awards are
        a great way to acknowledge your learners' hard work and accomplishments.
      </p>
      <p v-else>
        It looks like you have not setup an award yet. Awards are a great way to
        acknowledge your learners' hard work and accomplishments
      </p>
      <div v-if="!invalidCourse">
        <p v-if="awardType === 'who'">
          To get started, click the 'Set up award' button below. Once set up,
          your learners will receive well-deserved recognition for completing
          the course!
        </p>
        <p v-else>
          Set up an ‘<strong>Confirmation of participation</strong>’ to your
          course (Learner completes all mandatory learning activities, including
          self-reflection questions, and reaches the end of the course.).
        </p>
      </div>
    </div>
    <LxpButton variant="primary" @click="setUpAward()">{{
      invalidCourse ? "Try Again" : "Set up award"
    }}</LxpButton>
  </div>
</template>
<script>
import { LxpButton } from "didactica";
import { mapGetters } from "vuex";
export default {
  props: {
    invalidCourse: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LxpButton
  },
  computed: {
    ...mapGetters(["getCourseInfo"]),
    awardType() {
      return this.getCourseInfo?.org.toLowerCase().includes("who")
        ? "who"
        : "other";
    },
    courseId() {
      return this.$route.params.id;
    }
  },
  methods: {
    setUpAward() {
      if (this.invalidCourse) {
        this.$emit("tryAgain");
      } else {
        let type =
          this.awardType === "who"
            ? "course-completion-award"
            : "course-participation-confirmation";
        this.$router.push({
          name: "AwardForm",
          params: { id: this.courseId, type: type }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.aw-empty {
  @include center;
  padding: 32px;
  border-radius: 16px;
  background: $brand-neutral-0;
  box-shadow: 0px 1px 3px 1px rgba(33, 33, 33, 0.25);
  gap: 24px;
  .aw-empty__content {
    @include center;
    text-align: center;
    gap: 16px;
    padding: 8px;

    h5 {
      @include subtitle-regular;
      color: $brand-neutral-900;
      margin-bottom: 0;
    }
    p {
      @include body-regular;
      color: $brand-neutral-900;
      margin-bottom: 0;
    }
  }
}
</style>
