<template>
  <UserLayout
    :title="title"
    :hideRightSlot="hideRightSlot"
    :breadcrumbItems="breadcrumbsArr"
    showBreadcrumbs
    className="award-layout"
  >
    <LxpLoader v-if="isLoading" />
    <div class="awards-wrapper" v-else>
      <List v-if="getAward && !isInvalidCourse" :award="getAward" />
      <EmptyState
        :invalidCourse="isInvalidCourse"
        @tryAgain="validateCourseId"
        v-else
      />
    </div>
    <template v-slot:right-content>
      <div class="usl-right__content">
        <div class="usl-right__content--top">
          <label>What is a course award?</label>
        </div>
        <div class="usl-right__content--bottom">
          <p>
            A course award is a form of recognition given to learners upon
            completing a course/learning path.
          </p>
          <p>
            Course awards not only motivate learners to actively engage with
            course materials but also provide them with a sense of
            accomplishment and validation for their efforts. They can also serve
            as valuable awards to showcase to employers or educational
            institutions. Overall, course awards play a significant role in
            fostering a positive learning experience and encouraging continuous
            skill development.
          </p>
        </div>
      </div>
    </template>
  </UserLayout>
</template>

<script>
import { LxpLoader } from "didactica";
// components
import UserLayout from "@/components/Layout/UserLayout.vue";
import List from "./Awards/List.vue";
import EmptyState from "./Awards/EmptyState.vue";
import { mapGetters } from "vuex";
export default {
  components: { LxpLoader, UserLayout, List, EmptyState },
  data() {
    return {
      isLoading: true,
      isInvalidCourse: false
    };
  },
  computed: {
    ...mapGetters(["allConfig", "getAward", "getCourseInfo"]),
    title() {
      return this.$route.meta.title;
    },
    hideRightSlot() {
      let bool = false;
      if (this.award) {
        bool = true;
      }
      return bool;
    },
    courseId() {
      return this.$route.params.id;
    },
    breadcrumbsArr() {
      return [
        {
          text: this.getCourseInfo?.display_name,
          to: { name: "LearningSpacesHome" } //TODO update to lms url
        },
        {
          text: "Settings",
          to: { name: "Awards" } //TODO update to lms url
        }
      ];
    }
  },
  created() {
    this.validateCourseId();
  },
  methods: {
    validateCourseId() {
      this.$store
        .dispatch("validateCourse", { courseId: this.courseId })
        .then(() => {
          this.fetchAwards();
        })
        .catch(() => {
          this.isInvalidCourse = true;
          this.isLoading = false;
        });
    },
    fetchAwards() {
      this.$store
        .dispatch("fetchAwards", { courseId: this.courseId })
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    }
  }
};
</script>
<style lang="scss" scoped>
.awards-wrapper {
  margin-right: 8px;
}
.usl-right__content {
  .usl-right__content--top {
    margin-bottom: 10px;
  }
  label {
    @include label-large($brand-neutral-900, 500);
    margin-bottom: 0px;
    background: $brand-primary-100;
    padding: 8px;
    border-radius: 8px;
    width: 100%;
  }
  p {
    @include label-medium;
  }
}
</style>
<style lang="scss">
.award-layout {
  .usl-main__content {
    margin: 0 auto;
    max-width: 1028px;
    padding: 48px 0;
    .usl-main__header {
      flex-direction: column;
      align-items: flex-start !important;
    }
  }
}
</style>
